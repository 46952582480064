import * as types from "../types";

export const init = {
  lang: [],
  categoryId: [],
  durationGte: null,
  durationLte: null,
  ratingGte: null,
  priceGte: null,
  priceLte: null,
  availableFrom: null,
  availableTill: null,
};

export default function filters(state = init, action) {
  switch (action.type) {
    case types.SET_PRODUCTS_FILTERS:
      return {
        ...state,
        ...action?.payload,
      };

    case types.RESET_GENERAL_FILTERS: {
      const { lang, categoryId, durationGte, durationLte, ratingGte } = init;
      return {
        ...state,
        lang,
        categoryId,
        durationGte,
        durationLte,
        ratingGte,
      };
    }

    case types.RESET_PRICE_FILTERS: {
      const { priceGte, priceLte } = init;
      return {
        ...state,
        priceGte,
        priceLte,
      };
    }

    case types.SET_DATE_RANGE:
      return {
        ...state,
        availableFrom: action.payload.availableFrom,
        availableTill: action.payload.availableTill,
      };

    case types.RESET_DATE_FILTERS: {
      const { availableFrom, availableTill } = init;
      return {
        ...state,
        availableFrom,
        availableTill,
      };
    }

    case types.RESET_PRODUCTS_FILTERS:
      return init;
    default:
      return state;
  }
}

export const updateFilters = (dispatch, { storeBoth = false, ...newData }) => {
  dispatch({ type: types.SET_PRODUCTS_FILTERS, payload: { ...newData, storeBoth } });
};
