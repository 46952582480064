/* eslint-disable camelcase */
import { takeEvery, put, call, select } from "redux-saga/effects";
import { DEFAULT_LANG } from "../../constants";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";

/**
 * DEPRECATED!
 * Removes reviews which are excess with current used perPage
 * If there are not enough reviews to display first page - fetches last page again
 * @param {Object} action
 * @param {Object} action.perPage
 */
function* normalizeReviews({ type, perPage, ...fetchParams }) {
  const storedReviews = [...(yield select(state => state.reviews.reviews))];
  const excess = storedReviews.length % perPage;

  storedReviews.splice(storedReviews.length - excess, excess);
  yield put({ type: types.NORMALIZE_REVIEWS_SUCCESS, data: storedReviews, perPage });

  if (false) {
    yield put({
      type: types.FETCH_REVIEWS,
      per_page: perPage,
      page: Math.floor(storedReviews.length / perPage) + 1,
      ...fetchParams,
    });
  }
}

/**
 * Fetches reviews
 * @param {Object} action
 * @param {String} action.lang - code of the language
 * @param {Number} action.page - requested page of request
 * @param {Number?} action.productId - id of the product reviews related to
 * @param {Number?} action.per_page - amount of reviews per page, if not defined all reviews will be fetched
 * @param {Boolean?} action.replace - replace already stored reviews in the store
 */
function* reviewsFetch(action) {
  const { type, lang = DEFAULT_LANG, page = 1, productId, replace, ...queryParams } = action;

  try {
    const queryString = toQueryString({ page, lang, preorder: true, ...queryParams });
    const { data } = yield call(
      Api.get,
      `/api/v2/products/${productId ? `${productId}/` : ""}reviews/?${queryString}`,
      { lang },
    );
    yield put({ type: types.FETCH_REVIEWS_SUCCESS, data, replace });
  } catch (error) {
    yield put({ type: types.FETCH_REVIEWS_FAILURE, error });
  }
}

function* reviewsAuthorFetch(action) {
  const { lang = DEFAULT_LANG, page = 1, per_page, author, replace } = action;

  try {
    const queryString = toQueryString({ page, lang, preorder: true, per_page });
    const { data } = yield call(Api.get, `/api/v2/authors/${author}/reviews/?${queryString}`, {
      lang,
    });

    yield put({ type: types.FETCH_REVIEWS_SUCCESS, data, replace });
  } catch (error) {
    yield put({ type: types.FETCH_REVIEWS_FAILURE, error });
  }
}

export default function* watch() {
  yield takeEvery(types.NORMALIZE_REVIEWS, normalizeReviews);
  yield takeEvery(types.FETCH_AUTHOR_REVIEWS, reviewsAuthorFetch);
  yield takeEvery(types.FETCH_REVIEWS, reviewsFetch);
}
