import PropTypes from "prop-types";
import React from "react";
import { Trans } from "@lingui/macro";
import * as CATEGORY from "../../../constants/searchCategories";
import classNames from "../../../functions/classNames";
import Link from "../../Link";
import Price from "../../Price";
import HighlightedText from "../../../functions/search/highlightMatch";
import "./SearchItem.css";

/**
 * Search result
 * @param {Object} props
 * @param {String} props.link - link to the item page
 * @param {String} props.searchWord - request, affected this result, used to highlight part of found item's title
 * @param {String} props.title - item name
 * @param {String} props.text - additional info to the title
 * @param {String} props.category - type of the item (as a key from server's responce)
 * @param {String} props.currencyCode - currency code (e.g. RUB, EUR)
 * @param {Number} props.price - price, if exists
 * @param {Boolean} props.withoutLink - show event if no link specified
 */
function SearchItem(props) {
  const {
    link,
    title,
    text,
    category,
    searchWords,
    price,
    currencyCode,
    withoutLink,
    onClick,
  } = props;

  const categoryToClass = {
    [CATEGORY.ATTRACTIONS]: "SearchItem__attraction",
    [CATEGORY.CITIES]: "SearchItem__city",
    [CATEGORY.TOURS]: "",
    [CATEGORY.NOT_FOUND]: "",
  };

  const Wrapper = withoutLink ? "div" : Link;
  const wrapperProps = withoutLink ? { onClick } : { to: link, external: true, onClick };
  const isFree = price === 0;

  return link || withoutLink ? (
    <Wrapper className={classNames("SearchItem__row", categoryToClass[category])} {...wrapperProps}>
      <div>
        {/* Используем функцию highlightMatch для подсветки совпадающего массива символов */}
        <span
          className={classNames(
            category === CATEGORY.CITIES ? "SearchItem__highlightedPlace" : "SearchItem__title",
          )}
        >
          <HighlightedText searchWords={searchWords} text={title} />
        </span>
        <span className="SearchItem__place">
          {" "}
          <HighlightedText text={text} />
        </span>
      </div>
      {category === CATEGORY.TOURS && price ? (
        <span className="SearchItem__price">
          {!isFree ? <Price value={price} currencyCode={currencyCode} /> : <Trans>Free</Trans>}
        </span>
      ) : null}
    </Wrapper>
  ) : null;
}

SearchItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  category: PropTypes.string,
  searchWords: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
};

SearchItem.defaultProps = {
  title: ``,
  text: ``,
  category: ``,
  searchWords: ``,
  price: 0,
  currency: ``,
};

export default SearchItem;
