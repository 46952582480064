import React, {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import loadable from "@loadable/component";
import classNames from "../../functions/classNames";
import useOnClickOutside from "../../functions/react/useOnClickOutside";
import withProps from "../../functions/react/withProps";
import Button from "../Button";
import { Icon } from "../Icon";
import "./Dropdown.css";
import { isSSR } from "../NoSSR";
import { useMediaQuery } from "beautiful-react-hooks";

const DropdownBody = loadable(() => import("./DropdownBody"), { ssr: false });

export const DropdownButton = memo(
  forwardRef(
    (
      {
        buttonClassName,
        body,
        children,
        mergeHandlers,
        noButtonWrap,
        onClick = () => {},
        onClosed = () => {},
        onOpened = () => {},
        disableOutside,
        ...props
      },
      forwardedRef,
    ) => {
      const [isOpened, toggle] = useState(false);

      const handleClose = useCallback(() => {
        toggle(false);
        onClosed();
      }, [onClosed]);

      const handleOnClick = useCallback(() => {
        if (isOpened) handleClose();
        else {
          onOpened();
          toggle(true);
        }

        if (mergeHandlers) onClick();
      }, [handleClose, isOpened, mergeHandlers, onClick, onOpened]);

      const ref = useRef(null);
      useOnClickOutside(ref, () => {
        if (isOpened) handleClose();
      });

      // Pass methods to the parent
      useImperativeHandle(forwardedRef, () => ({ handleOpen: handleOnClick, handleClose }), [
        handleOnClick,
        handleClose,
      ]);
      const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");

      return (
        <div ref={ref} className="Dropdown">
          {noButtonWrap && children ? (
            withProps(children)({ onClick: handleOnClick })
          ) : (
            <Button
              className={classNames(
                "DropdownButton__button",
                !props.icon && "DropdownButton__button--chevron",
                buttonClassName,
              )}
              theme="simple"
              iconPosition="after"
              icon={isMobile ? <Icon name={`chevron/${isOpened ? "up" : "down"}`} /> : null}
              onClick={handleOnClick}
              {...props}
            >
              {children}
            </Button>
          )}
          {isOpened && body && (
            <>
              {withProps(body)({ handleClose })}

              {disableOutside && <div className="Dropdown__outside-close" onClick={handleClose} />}
            </>
          )}
        </div>
      );
    },
  ),
);

/**
 * Wraps component in DropdownBody
 * @param {React::Component} Component
 */
export function withDropdownBody(Component) {
  /**
   * Component wrapped in DropdownBody
   * @param {Object} $ - will be passed to original Component, except props for DropdownBody mentioned below
   * @param {String} $.bodyClassName - className for the DropdownBody
   */
  return ({
    bodyClassName,
    children,
    handleClose = () => {},
    bottomButtonProps,
    altBottomButtonProps,
    title,
    ...props
  }) => {
    const bottomButtons = [altBottomButtonProps, bottomButtonProps];
    const ButtonsWrapperTag = bottomButtonProps && altBottomButtonProps ? "div" : Fragment;
    return (
      <DropdownBody className={bodyClassName}>
        {title && <div className="DropdownBody__title">{title}</div>}
        <Component {...props} handleClose={handleClose}>
          {children}
        </Component>
        {!!bottomButtons.length && (
          <ButtonsWrapperTag
            {...(ButtonsWrapperTag === "div" ? { className: "DropdownBody__bottomButtons" } : {})}
          >
            {[altBottomButtonProps, bottomButtonProps].map(
              ({ willClose, onClick, children: buttonChildren, isAlt, ...buttonProps } = {}) =>
                buttonChildren && (
                  <Button
                    key={buttonChildren}
                    {...buttonProps}
                    className={classNames(
                      "DropdownBody__bottomButton",
                      isAlt && "DropdownBody__bottomButton--alt",
                      buttonProps.className,
                    )}
                    size="M"
                    onClick={() => {
                      if (onClick) onClick();
                      if (willClose) handleClose();
                    }}
                  >
                    {buttonChildren}
                  </Button>
                ),
            )}
          </ButtonsWrapperTag>
        )}
      </DropdownBody>
    );
  };
}
