import { isSSR } from "../components/NoSSR";

/**
 * Payment method types
 * @type {Object}
 */
export const PAYMENT_METHODS = {
  ApplePay: "AP",
  GooglePay: "GP",
  CreditCard: "AC",
};

/**
 * All available payment gate codes
 * @type {Object}
 */
export const PAYMENT_GATES = {
  MasterCard: 0,
  Visa: 1,
  Mir: 2,
  ApplePay: 3,
  GooglePay: 4,
  Maestro: 5,
  UnionPay: 6,
  AmericanExpress: 7,
  CartesBancaires: 8,
};

export const PAYMENT_METHODS_ICONS = {
  AP: "pay/apple",
  GP: "pay/google",
};

/**
 * All available types of payment gates
 * @type {Object}
 */
export const PAYMENT_GATE_TYPES = {
  Card: 0,
  NFC: 1,
};

/**
 * Gets payment methods available on current device
 * @param {String} currencyCode - currency code from backend (e.g. `"RUB"`)
 * @param {Boolean} checkApi - should check ability to access API of payment system
 * @returns {Array[String]} - strings from `PAYMENT_METHODS` values
 */
export function availablePaymentMethods(currencyCode, checkApi = true) {
  const paymentMethods = [];

  if (currencyCode !== "RUB") {
    const isApplePayAvailable = !isSSR && window.ApplePaySession !== undefined;

    // Enhanced Google Pay availability check
    const isGoogleAvailable =
      !isSSR &&
      window.PaymentRequest !== undefined &&
      navigator.userAgent.toLowerCase().indexOf("android") !== -1;

    if (!checkApi || isApplePayAvailable) {
      paymentMethods.push(PAYMENT_METHODS.ApplePay);
    }
    if (!checkApi || (!isApplePayAvailable && isGoogleAvailable)) {
      paymentMethods.push(PAYMENT_METHODS.GooglePay);
    }
  }

  return [...paymentMethods, PAYMENT_METHODS.CreditCard];
}

/**
 * Gets card gates acceptable by checkout payment system
 * @param {String} currencyCode - selected currency code (e.g. `"RUB"`)
 * @returns {Array[String]} - acceptable card gate ids (from `PAYMENT_GATES`)
 */
export function availableCardGates(currencyCode) {
  const defaultCardGates = [
    "Visa",
    "MasterCard",
    "Maestro",
    "AmericanExpress",
    "CartesBancaires",
    "UnionPay",
  ].map(m => PAYMENT_GATES[m]);

  if (availablePaymentMethods(currencyCode).includes(PAYMENT_METHODS.CreditCard)) {
    const { Mir, MasterCard, Visa } = PAYMENT_GATES;
    switch (currencyCode) {
      case "RUB":
        return [Mir, MasterCard, Visa];
      default:
        return defaultCardGates;
    }
  }

  return [];
}

/**
 * Gets NFC gates acceptable by checkout payment system
 * @param {String} currencyCode - selected currency code (e.g. `"RUB"`)
 * @param {Boolean} checkApi - should check ability to access API of payment system
 * @returns {Array[String]} - all available payment gate ids (from `PAYMENT_GATES`)
 */
export function availableNFCGates(currencyCode, checkApi = true) {
  return availablePaymentMethods(currencyCode, checkApi).reduce((acc, m) => {
    switch (m) {
      case PAYMENT_METHODS.ApplePay:
        return [...acc, PAYMENT_GATES.ApplePay];
      case PAYMENT_METHODS.GooglePay:
        return [...acc, PAYMENT_GATES.GooglePay];
      default:
        return acc;
    }
  }, []);
}

/**
 * Gets all available gates acceptable by checkout payment system
 * @param {String} currencyCode - selected currency code (e.g. `"RUB"`)
 * @param {Array} gateTypes - type of gates (ids from `PAYMENT_GATE_TYPE`)
 * @returns {Array} - all available payment gate ids (from `PAYMENT_GATES`)
 */
export function availablePaymentGates(currencyCode, gateTypes) {
  return gateTypes.reduce((acc, gt) => {
    switch (gt) {
      case PAYMENT_GATE_TYPES.NFC:
        return [...acc, ...availableNFCGates(currencyCode)];
      case PAYMENT_GATE_TYPES.Card:
        return [...acc, ...availableCardGates(currencyCode)];
      default:
        return acc;
    }
  }, []);
}

/**
 * Gets all possible gates acceptable by checkout payment system
 * Not paying attention to NFC API availability
 * @param {String} currencyCode - selected currency code (e.g. `"RUB"`)
 * @returns {Array} - all available payment gate ids (from `PAYMENT_GATES`)
 */
export function allPaymentGates(currencyCode) {
  return [...availableCardGates(currencyCode), ...availableNFCGates(currencyCode, false)];
}

/**
 * Checks if payment and tickets was processed
 * @param {Object} payment - from `/api/v2/payments/${ID}/` API
 * @param {String} statusType - check status for payment (`"payment"`) or for both (`"tickets"`, default)
 * @returns {Boolean}
 */
export function isPaymentComplete(payment, statusType = "tickets") {
  const { status, access, hash, tickets, link } = payment;
  return statusType === "payment"
    ? typeof status === "string" && Boolean(access || link)
    : status === "done" && access && (!tickets || !tickets.length || (hash && tickets.length));
}
