import * as types from "../types";

export const init = {
  reviews: [],
  count: null,
  current: 0,
  pages: null,
  loading: false,
  author: null,
};

export default function reviews(state = {}, action) {
  switch (action.type) {
    case types.FETCH_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        // TODO: @gigafiga fix plz, if this code uncommented the second page of reviews isn't loading
        // reviews: !action.data.current
        //   ? [...state.reviews, ...action.data.results]
        //   : [...action.data.results],
        reviews: action.replace ? action.data.results : [...state.reviews, ...action.data.results],
        pages: action.data.pages,
        count: action.data.count,
        current: action.data.current,
        averageRating: action.data.avg_rating,
        loading: false,
      };
    case types.FETCH_AUTHOR_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_AUTHOR_REVIEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_AUTHOR_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.replace ? action.data.results : [...state.reviews, ...action.data.results],
        pages: action.data.pages,
        count: action.data.count,
        current: action.data.current,
        averageRating: action.data.avg_rating,
        loading: false,
      };
    case types.NORMALIZE_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.data,
        pages: Math.ceil(state.count / action.perPage),
        current: Math.floor(action.data.length / action.perPage),
      };

    default:
      return state;
  }
}
