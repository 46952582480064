import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_ATTRACTIONS_REQUEST,
  FETCH_ATTRACTIONS_SUCCESS,
  FETCH_ATTRACTIONS_FAILURE,
} from "../types";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";

function* fetchAttractions(action) {
  try {
    const { lang, cityId } = action.payload;
    const response = yield call(
      Api.get,
      `/api/v2/attractions/?${toQueryString({
        lang,
        expand: "h1,meta_title,meta_description,description",
        preorder: true,
        city: cityId,
        sort: "quantity",
      })}`,
      { lang },
    );
    yield put({ type: FETCH_ATTRACTIONS_SUCCESS, payload: response.data.results });
  } catch (e) {
    yield put({ type: FETCH_ATTRACTIONS_FAILURE, payload: e.message });
  }
}

export default function* attractionsSaga() {
  yield takeEvery(FETCH_ATTRACTIONS_REQUEST, fetchAttractions);
}
