import React from "react";

import { asyncComponent } from "@jaredpalmer/after";

/**
 * Placeholder for showing while page loads during lazy-loading
 */
const Placeholder = () => (
  <div
    className="w"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,"Open Sans", "Helvetica Neue", sans-serif',
    }}
  >
    <style type="text/css">{`.w{width:100%;height:100%}.i{display:inline-block}.a{display:inline-block;position:absolute;content:'';background:#22ccd8;border-radius:50%;animation:1s p 0s linear infinite}.b{animation-delay:.5s;transform:scale(0.2)}@keyframes p{from{opacity:1;transform:scale(0.2)}to{opacity:0;transform:scale(1)}}`}</style>
    <span
      className="i"
      style={{ width: "40px", height: "40px", marginRight: "20px", position: "relative" }}
    >
      <span className="w a i" />
      <span className="w a b i" />
    </span>
    Loading...
  </div>
);

export default [
  {
    path: "/:lang?/unsubscribe/",
    name: "unsubscribe",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Unsubscribe"),
      Placeholder,
    }),
  },
  {
    path: "(/)?:lang([a-z]{2})?/login/",
    name: "login",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Login"),
      Placeholder,
    }),
  },
  {
    path: "(/)?:lang([a-z]{2})?/login-magic/",
    name: "login-magic",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Login"),
      Placeholder,
    }),
  },
  {
    path: "/",
    name: "root",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Main"),
      Placeholder,
    }),
  },
  {
    path: "/:lang([a-z]{2})?/",
    name: "main",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Main"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/generate-your-tour/",
    name: "generate-your-tour",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/OnDemandTours"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:countrySlug-s:countryId([0-9]+)/",
    name: "country",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Country"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/:categorySlug-c:categoryId([0-9]+)/",
    name: "category",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Collection"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/:categorySlug-c:categoryId([0-9]+)/:subcategorySlug-sc:subcategoryId([0-9]+)/",
    name: "subCategory",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Collection"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/",
    name: "city",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/City"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-b:cityId([0-9]+)/",
    name: "cityTest",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/City"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:attractionSlug-a:attractionId([0-9]+)/",
    name: "attraction-simple",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Attraction"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/:attractionSlug-a:attractionId([0-9]+)/",
    name: "attraction",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Attraction"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:attractionSlug-t:attractionId([0-9]+)/",
    name: "attraction-tickets-simple",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Tickets"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/:attractionSlug-t:attractionId([0-9]+)/",
    name: "attraction-tickets",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Tickets"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/:citySlug-d:cityId([0-9]+)/:productSlug-p:productId([0-9]+)/",
    name: "product",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Product"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/checkout/:productSlug-p:productId([0-9]+)/:step(booking|personal|payment)?/:paymentId?/:confirmationCode?/",
    name: "checkout",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Checkout"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/order/:paymentId/:confirmationCode/:step(access|invite|verification)?/",
    name: "order",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Order"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/destinations/",
    name: "destinations",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Destinations"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/bookings/",
    name: "bookings",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Bookings"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/booking/:code",
    name: "booking",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Booking"),
      Placeholder,
    }),
  },
  {
    // TODO: optional lang
    path: "/:lang?/favorites/",
    name: "favorites",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Favorites"),
      Placeholder,
    }),
  },
  {
    // TODO: add trailing slash
    path: "/:lang?/affiliate/:tab(widgets|stats|profile|links|payouts)?",
    name: "affiliate",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Affiliate"),
      Placeholder,
    }),
  },
  {
    path: "/:lang?/widgets/",
    name: "widgets",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Widgets"),
      Placeholder,
    }),
  },
  {
    path: "/@:nickname/",
    name: "author",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Author"),
      Placeholder,
    }),
  },
  {
    path: "/:lang([a-z]{2})?/@:nickname/",
    name: "author-localized",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Author"),
      Placeholder,
    }),
  },
  {
    path: "/provider/:nickname/",
    name: "provider",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Provider"),
      Placeholder,
    }),
  },
  {
    path: "/review/:orderID/:citySlug-d:cityId([0-9]+)/:step(done)?/",
    name: "review",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/Review"),
      Placeholder,
    }),
  },
  // 404
  {
    component: asyncComponent({
      loader: () => import("./pages/ErrorPage"),
      Placeholder,
    }),
  },
];
